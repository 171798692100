import {NgClass, NgIf} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    inject,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DataStore} from '@compiere-ws/models/compiere-data-json';
import {LocationService} from '@compiere-ws/services/compiere-location/location.service';
import SpecificWindowUiComponent
    from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import {AppConfig} from '@iupics-config/app.config';
import {CacheManagerService} from '@iupics-manager/managers/cache-manager/cache-manager.service';
import {AbstractDataContainer, AbstractDataContainerCallout} from '@iupics-manager/models/abstract-datacontainer';
import {DynamicComponent} from '@iupics-manager/models/dynamic-component';
import {Global} from '@iupics-manager/models/global-var';
import {IupicsDataField} from '@iupics-manager/models/iupics-data';
import {IupicsTypeEvent} from '@iupics-manager/models/iupics-event';
import {TextLimitPipe} from '@iupics-util/pipes/text-limit/text-limit.pipe';
import {TooltipModule} from 'primeng/tooltip';
import PrimeOverlayComponent from '../../../overrided/prime-overlay/prime-overlay.component';
import ValuePreferencePanelComponent from '../../value-preference-panel/value-preference-panel.component';

@Component({
    selector: 'iu-input-location-ui',
    templateUrl: './input-location-ui.component.html',
    styleUrls: ['./input-location-ui.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        TooltipModule,
        NgIf,
        NgClass,
        FormsModule,
        PrimeOverlayComponent,
        ValuePreferencePanelComponent,
        TextLimitPipe,
        ModalUiComponent,
    ],
})
export default class InputLocationUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
    #config = inject(AppConfig);
    #locationService = inject(LocationService);
    #cd = inject(ChangeDetectorRef);

    @Input() data: IupicsDataField;

    @Input()
    columnName: string;

    @ViewChild('vcrLocationPanel', {read: ViewContainerRef, static: false})
    vcrLocationPanel: ViewContainerRef;
    @ViewChild('input', {static: true})
    inputRef: ElementRef;
    dataContainers: AbstractDataContainerCallout;
    locationPanelComponent: SpecificWindowUiComponent;
    displayLocationPanel = false;

    constructor() {
        super();
        this.isAddressField = true;
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.isStandalone && !this.data) {
            this.data = {};
        }
        if (this.cssClass !== undefined) {
            this.cssGrid = this.cssClass;
        }
        this.cssClass = ' ' + this.cssGrid;
        this.setFieldMandatory();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        let parentComp = this.DOMParentComponent;
        while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
            parentComp = parentComp.DOMParentComponent;
        }
        if (parentComp instanceof EditTabUiComponent) {
            this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
        }
    }

    blocInput() {
        return false;
    }

    onLocationEmitter(response, fromGoogle = false) {
        if (response) {
            switch (response.action) {
                case 'clear':
                    this.fieldValue = null;
                    this.dataChange(null);
                    break;
                case 'save':
                    this.fieldValue = response.result;
                    this.dataChange(response.result);
                    break;
                default:
                    break;
            }
            this.toggleOverlay(response.event);
        }
    }

    zoomAcross() {
        this.isZoom = true;
        let record_id = -1;
        if (this.fieldValue) {
            record_id = this.fieldValue.id;
        }
        this.subscriptions.push(
            this.uiCreatorService.zoomAcross(this.data.details.tableName, this.data.details.keyColumn, record_id).subscribe({
                next: (dataWs) => {
                    if (dataWs && dataWs.length > 0) {
                        this.zoomInfo = {
                            windowId: dataWs[0].Window_ID,
                            dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
                            record_id: record_id,
                            children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null,
                        };
                        if (this.data.isParam || this.container instanceof SpecificWindowUiComponent) {
                            this.isZoom = false;
                            Global.workspace.openTargetSearch({
                                zoomInfo: this.zoomInfo,
                                cat: {id: parseInt(dataWs[0].Window_ID, 10)},
                                source: {
                                    id: record_id !== -1 ? dataWs[0].Record_ID : 'newRecord',
                                },
                            });
                        } else {
                            const windowId = parseInt(dataWs[dataWs.length - 1]['Window_ID'], 10);
                            this.subscriptions.push(
                                this.uiCreatorService.getWindow(windowId).subscribe((tabUI) => {
                                    const item: DynamicComponent = {
                                        container: this.container,
                                        DOMParentComponent: this.container,
                                        linkedComponents: [this],
                                        component: 'EditViewUiComponent',
                                        cssClass: 'iupics-blade-content',
                                        isCssOnComponent: false,
                                        tabId: tabUI.tabId,
                                        windowId: windowId,
                                        zoomInfo: this.zoomInfo,
                                    };
                                    this.componentEmitter.emit({
                                        type: IupicsTypeEvent.showEditView,
                                        item: item,
                                    });
                                })
                            );
                        }
                    }
                },
                error: (err) => {
                    this.isZoom = false;
                },
            })
        );
    }

    refreshZoomInfo() {
        let record_id = -1;
        if (this.fieldValue) {
            record_id = this.fieldValue.id;
        }
        this.zoomInfo.record_id = record_id;
        this.zoomInfo.dataUUID = this.data.details.keyColumn + ',' + record_id;
        if (!this.zoomInfo.children && this.zoomInfo.record_id !== -1) {
            this.notifierLinkedComponent.next({
                type: IupicsTypeEvent.selectZoomChange,
                item: {
                    container: null,
                    dataStoreKey: null,
                    zoomInfo: this.zoomInfo,
                },
            });
        }
    }

    onSiblingUpdate(event: any) {
        if (event && event.refreshZoom) {
            this.resetLocationFromZoom(event.id);
        }
    }

    resetLocationFromZoom(id: any) {
        if (id === null) {
            this.dataChange(null);
        } else {
            this.subscriptions.push(
                this.#locationService.getLocation(id).subscribe((response) => {
                    this.fieldValue = {
                        id: id,
                        displayValue: this.#locationService.parseAddress(response, response.DisplaySequence)
                    };
                    this.dataChange(this.fieldValue);
                })
            );
        }
    }

    changeFieldValue(dataStored: DataStore, fromOtherChange: boolean = false, calloutStack: string[] = []) {
        super.changeFieldValue(dataStored, fromOtherChange, calloutStack);
        const columnName = this?.data?.columnName || 'C_Location_ID';
        const id = this.fieldValue?.id || this?.fieldValue || null;
        const idStored = dataStored?.data?.[columnName]?.id || dataStored.data[columnName];

        const displayValue = this.fieldValue?.displayValue || null;
        const displayValueStored =
            dataStored?.data?.[columnName]?.displayValue || null;
        if (id && (id !== idStored || (displayValueStored && displayValue !== displayValueStored) || !displayValue)) {
            this.fieldValue = {
                id: id,
                displayValue: '...',
            };
            const sub = this.#locationService.getLocation(id).subscribe({
                next: (response) => {
                    if (response) {
                        this.fieldValue = {
                            id: id,
                            displayValue: this.#locationService.parseAddress(response, response.DisplaySequence)
                        };
                    }
                    sub.unsubscribe();
                },
                error: (error) => {
                    console.error('c_location_id not found: ' + id);
                },
            });
        }
    }

    createSpecificWindow() {
        const formID = this.#config.getConstant('LocationPanelComponent#FormID');
        this.subscriptions.push(
            (this.#config.isModuleEnable('newLocation')
                    ? this.uiCreatorService.getLocationPanel(formID)
                    : this.uiCreatorService.getSpecificWindow(formID)
            ).subscribe((specificWindow) => {
                let component;
                if (
                    specificWindow.angularClass &&
                    specificWindow.angularClass.length > 0 &&
                    specificWindow.angularClass !== 'default'
                ) {
                    component = CacheManagerService.iupics_specific_window.get(specificWindow.angularClass);
                }
                if (!component) {
                    component = CacheManagerService.iupics_specific_window.get('default');
                }
                this.vcrLocationPanel.clear();
                const componentRef = this.vcrLocationPanel.createComponent(component);
                // this.specificWindowTitle = specificWindow.name;
                (<SpecificWindowUiComponent>componentRef.instance).name = specificWindow.name;
                (<SpecificWindowUiComponent>componentRef.instance).title = specificWindow.title;
                (<SpecificWindowUiComponent>componentRef.instance).description = specificWindow.description;
                (<SpecificWindowUiComponent>componentRef.instance).help = specificWindow.help;
                (<SpecificWindowUiComponent>componentRef.instance).componentRef = componentRef;
                (<SpecificWindowUiComponent>componentRef.instance).isModal = true;
                (<SpecificWindowUiComponent>componentRef.instance).formId = formID;
                (<SpecificWindowUiComponent>componentRef.instance).vcrwindow = this.vcrLocationPanel;
                (<SpecificWindowUiComponent>componentRef.instance).parentComponent = this;
                (<SpecificWindowUiComponent>componentRef.instance).index = this.vcrLocationPanel.length - 1;
                (<SpecificWindowUiComponent>componentRef.instance).sourceModal = null;
                (<SpecificWindowUiComponent>componentRef.instance).sourceComponentData =
                    this && this.itemData ? this.itemData : this;
                (<SpecificWindowUiComponent>componentRef.instance).sourceComponent =
                    this;
                /**dupliqué si pas d'itemdata mais trop de refactoring sur scout */
                (<SpecificWindowUiComponent>componentRef.instance).closeModalEmitter.subscribe((_event) => {
                    this.toggleOverlay(_event);
                });
                this.locationPanelComponent = <SpecificWindowUiComponent>componentRef.instance;
                this.componentRefs.push(componentRef);
            })
        );
    }

    // make location panel appended to body
    toggleOverlay(event) {
        if (this.displayLocationPanel) {
            this.displayLocationPanel = false;
        } else {
            this.displayLocationPanel = true;
            this.#cd.detectChanges();
            this.createSpecificWindow();
        }
    }
}
